<template>
  <v-container
    fluid
    tag="section"
  >
    <v-row>
      <div class="text-center">
        <base-material-snackbar
          v-model="updateOk"
          :type="colorSnackbarSuccess"
          v-bind="{
            [parsedDirection[0]]: true,
            [parsedDirection[1]]: true
          }"
        >
          <span class="font-weight-bold"> La commande a bien été modifié.</span>
        </base-material-snackbar>

        <base-material-snackbar
          v-model="KOupdate"
          :type="colorSnackbarError"
          v-bind="{
            [parsedDirection[0]]: true,
            [parsedDirection[1]]: true
          }"
        >
          <span class="font-weight-bold">Erreur lors de la modification de la commande.</span>
        </base-material-snackbar>

        <base-material-snackbar
          v-model="envoiMailPreparationOK"
          :type="colorSnackbarSuccess"
          v-bind="{
            [parsedDirection[0]]: true,
            [parsedDirection[1]]: true
          }"
        >
          <span class="font-weight-bold"> Le mail de preparation a été envoyé</span>
        </base-material-snackbar>

        <base-material-snackbar
          v-model="envoiMailPreparationKO"
          :type="colorSnackbarSuccess"
          v-bind="{
            [parsedDirection[0]]: true,
            [parsedDirection[1]]: true
          }"
        >
          <span class="font-weight-bold"> Erreur lors de l'envoi du mail de préparation</span>
        </base-material-snackbar>

        <base-material-snackbar
          v-model="envoiMailEnvoiCommandeOK"
          :type="colorSnackbarSuccess"
          v-bind="{
            [parsedDirection[0]]: true,
            [parsedDirection[1]]: true
          }"
        >
          <span class="font-weight-bold"> Le mail de suivi de commande a été envoyé</span>
        </base-material-snackbar>

        <base-material-snackbar
          v-model="envoiMailEnvoiCommandeKO"
          :type="colorSnackbarError"
          v-bind="{
            [parsedDirection[0]]: true,
            [parsedDirection[1]]: true
          }"
        >
          <span class="font-weight-bold"> Erreur lors de l'envoi du mail de suivi de commande</span>
        </base-material-snackbar>
      </div>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="9"
      >
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <base-material-card
              style="padding:7px !important"
              title="Informations client"
            >
              <template>
                <div class="text-h3 font-weight-light">
                  <v-simple-table>
                    <thead>
                      <tr>
                        <th class="primary--text">
                          Nom
                        </th>
                        <th class="primary--text">
                          Prenom
                        </th>
                        <th class="primary--text">
                          Mail
                        </th>
                        <th class="primary--text">
                          Telephone
                        </th>
                        <th class="primary--text">
                          Statut
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td> {{ infoClient.nom }} </td>
                        <td> {{ infoClient.prenom }} </td>
                        <td> {{ infoClient.mail }} </td>
                        <td>{{ infoClient.telephone }}</td>
                        <td>{{ infoClient.statusLibelle }}</td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </div>
              </template>
              <v-row>
                <v-col>
                  <base-material-card
                    title="Adresse Facturation"
                  >
                    <br> Client : {{ infoCommande.adresse_facturation.nom }} {{ infoCommande.adresse_facturation.prenom }}
                    <span v-if="infoCommande.adresse_facturation.societe !== ''"> <br> Societe : {{ infoCommande.adresse_facturation.societe }}</span>
                    <br> Adresse: {{ infoCommande.adresse_facturation.adresse }}
                    <br> Cplt Adr : {{ infoCommande.adresse_facturation.complement_adresse }}
                    <br> CP / Ville : {{ infoCommande.adresse_facturation.code_postal }} {{ infoCommande.adresse_facturation.ville }}
                    <br> Pays :{{ infoCommande.adresse_facturation.pays }}
                  </base-material-card>
                </v-col>
                <v-col>
                  <base-material-card
                    title="Adresse Livraison"
                  >
                    <br> Client :{{ infoCommande.adresse_livraison.nom }} {{ infoCommande.adresse_livraison.prenom }}
                    <span v-if="infoCommande.adresse_livraison.societe !== ''"> <br> Societe : {{ infoCommande.adresse_livraison.societe }}</span>
                    <br> Adresse : {{ infoCommande.adresse_livraison.adresse }}
                    <br> Cplt Adr : {{ infoCommande.adresse_livraison.complement_adresse }}
                    <br> CP / Ville :  {{ infoCommande.adresse_livraison.code_postal }} {{ infoCommande.adresse_livraison.ville }}
                    <br> Pays : {{ infoCommande.adresse_livraison.pays }}
                  </base-material-card>
                </v-col>
              </v-row>
            </base-material-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <base-material-card
              style="padding:7px !important"
              title="Informations de la commande"
            >
              <div class="text-h3 font-weight-light">
                <v-simple-table>
                  <thead>
                    <tr>
                      <th class="primary--text">
                        N° commande
                      </th>
                      <th class="primary--text">
                        Date
                      </th>
                      <th class="primary--text">
                        Type Paiement
                      </th>
                      <th class="primary--text">
                        Montant
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td style="font-size: 12px;">
                        {{ infoCommande.numero }}
                      </td>
                      <td style="font-size: 12px;">
                        Le {{ infoCommande.dateFormater }} à {{ infoCommande.heureCommande }}
                      </td>
                      <td> {{ infoCommande.labelTypePaiemeent }}</td>
                      <td>{{ (parseFloat(infoCommande.montant) + parseFloat(9.90)).toFixed(2) }} €</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </div>
            </base-material-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <base-material-card
              title="Details de la commande"
              style="padding:7px !important"
            >
              <template>
                <div class="text-h3 font-weight-light">
                  <v-simple-table>
                    <thead>
                      <tr>
                        <th class="primary--text">
                          Marque
                        </th>
                        <th class="primary--text">
                          Modele
                        </th>
                        <th class="primary--text" />
                        <th class="primary--text" />
                        <th class="primary--text" />
                        <th class="primary--text" />
                        <th class="primary--text" />
                        <th class="primary--text" />
                        <th class="primary--text">
                          Quantité
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="produit in produits"
                        :key="produit.id"
                      >
                        <td> {{ produit.marque }} </td>
                        <td> {{ produit.modele }} </td>
                        <td> {{ produit.descriptif.largeur }} </td>
                        <td> {{ produit.descriptif.diametre }} </td>
                        <td> {{ produit.descriptif.offset }} </td>
                        <td> {{ produit.descriptif.alesage }} </td>
                        <td> {{ produit.descriptif.entraxe }} </td>
                        <td> {{ produit.descriptif.couleur }} </td>
                        <td> {{ produit.quantite }} </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </div>
              </template>
            </base-material-card>
          </v-col>
          <base-material-snackbar
            v-model="updateOk"
            :type="colorSnackbarSuccess"
            v-bind="{
              [parsedDirection[0]]: true,
              [parsedDirection[1]]: true
            }"
          >
            <span class="font-weight-bold"> La commande a bien été modifié.</span>
          </base-material-snackbar>

          <base-material-snackbar
            v-model="KOupdate"
            :type="colorSnackbarError"
            v-bind="{
              [parsedDirection[0]]: true,
              [parsedDirection[1]]: true
            }"
          >
            <span class="font-weight-bold"> Erreur lors de la modification de la commande</span>
          </base-material-snackbar>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <base-material-card
              title="Accessoires"
              style="padding:7px !important"
            >
              <template>
                <div class="text-h3 font-weight-light">
                  <v-simple-table>
                    <thead>
                      <tr>
                        <th class="primary--text">
                          Marque
                        </th>
                        <th class="primary--text">
                          Modele
                        </th>
                        <th class="primary--text">
                          Quantite
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="produit in produitsAccessoires"
                        :key="produit.id"
                      >
                        <td> {{ produit.marque }} </td>
                        <td> {{ produit.modele }} </td>
                        <td> {{ produit.quantite }} </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </div>
              </template>
            </base-material-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <base-material-card
              title="Note interne"
              style="padding:7px !important"
            >
              <v-textarea
                v-model="infoCommande.note"
                class="purple-input"
                label="Note"
              />
              <v-btn
                color="edit"
                class="float-right"
                @click="ModifierCommande([infoCommande,produits])"
              >
                <v-icon left>
                  mdi-pencil
                </v-icon>
                Editer
              </v-btn>
            </base-material-card>
          </v-col>
        </v-row>
        <v-btn
          v-if="FromUser==true"
          @click="GoDetailsUser(user)"
        >
          <v-icon left>
            mdi-arrow-left-thick
          </v-icon>
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-card-text>
          <v-row>
            <v-btn
              color="success"
              class="mr-2 mt-4"
              @click="ExporterCommande(infoCommande)"
            >
              <v-icon
                class="mr-2"
                background-color="success"
              >
                mdi-file-pdf-box
              </v-icon>
              Générer une Facture
            </v-btn>

            <v-btn
              color="orange"
              class="mr-2 mt-4"
              @click="MailPreparation(infoCommande)"
            >
              <v-icon
                class="mr-2"
                background-color="success"
              >
                mdi-email
              </v-icon>
              Mail de preparation
            </v-btn>
            <v-btn
              color="warning"
              class="mr-2 mt-4"
              @click="MailLivraison(infoCommande)"
            >
              <v-icon
                class="mr-2"
                background-color="success"
              >
                mdi-truck
              </v-icon>
              Mail de Livraison
            </v-btn>
            <v-btn
              color="info"
              class="mr-2 mt-4"
              @click="ModifierCommande([infoCommande,produits])"
            >
              <v-icon
                class="mr-2"
                background-color="success"
              >
                mdi-email
              </v-icon>
              Valider les informations
            </v-btn>
            <span class="mt-4"> Statut de la commande </span>
            <v-select
              v-model="infoCommande.statut"
              :items="status"
              outlined
              solo
              class="mt-3"
              label="statut de la commande"
              item-value="statutId"
              item-text="statutLabel"
            />
            <v-text-field
              v-model="infoCommande.numero_colis"
              label="Solo"
              placeholder="N° Colis"
              solo
            />
            <v-text-field
              v-model="infoCommande.url_suivi_colis"
              label="Solo"
              placeholder="URL Colis"
              solo
            />
            <base-material-card
              title="Voiture Client"
            >
              <template>
                <div
                  class="text-h3 font-weight-light"
                  style="font-size: 1.25rem !important ;margin: 5px 19px;"
                >
                  <v-row> Marque: {{ infoCommande.vehicule.marque }} </v-row>
                  <v-row> Modele:  {{ infoCommande.vehicule.modele }}</v-row>
                  <v-row> Type:  {{ infoCommande.vehicule.type }}</v-row>
                  <v-row> Motorisation:  {{ infoCommande.vehicule.motorisation }}</v-row>
                  <v-row> MEC:  {{ infoCommande.circulation }}</v-row>
                  <v-row> Valves:  {{ infoCommande.valves }}</v-row>
                </div>
              </template>
            </base-material-card>
          </v-row>
        </v-card-text>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  // Utilities
  import axios from 'axios'
  import VueAxios from 'vue-axios'
  import Vue from 'vue'

  import JSPDF from 'jspdf'
  import {
    mapState,
  } from 'vuex'

  Vue.use(VueAxios, axios)

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      transfertDataLayer: false,
      produitsTmp: [],
      note: '',
      statusCommandeSelected: [{
        statutId: 1,
      }],
      FromUser: false,
      infoUser: '',
      InformationNumeroCommande: 'Informations commande',
      infoClient: '',
      updateOk: false,
      KOupdate: false,
      envoiMailPreparationOK: false,
      envoiMailPreparationKO: false,
      envoiMailEnvoiCommandeOK: false,
      envoiMailEnvoiCommandeKO: false,
      colorSnackbarSuccess: 'success',
      colorSnackbarError: 'error',
      infoCommande: '',
      statutItem: 'Publié',
      produits: [],
      produitsTmp2: [],
      produitsTmp3: [],
      produitsAccessoires: [],
      userId: '',
      infoCommandeBDD: [],
      direction: 'top right',
      status: [{
        statutId: 1,
        statutLabel: 'En attente de paiement',
      }, {
        statutId: 2,
        statutLabel: 'Payé',
      }, {
        statutId: 3,
        statutLabel: 'Préparation',
      }, {
        statutId: 4,
        statutLabel: 'Pris en charge',
      }, {
        statutId: 5,
        statutLabel: 'Annulé',
      }, {
        statutId: 6,
        statutLabel: 'Supprimé',
      }, {
        statutId: 7,
        statutLabel: 'Remboursé',
      }, {
        statutId: 8,
        statutLabel: 'Pré-commande',
      },
      ],
      imgFactureEJ: require('@/assets/ej-logo-baseline.jpg'),
      imgFactureDJ: require('@/assets/dj-logo.png'),
      VUE_APP_SITE_ID: null,
    }),
    computed: {
      ...mapState(['barColor', 'barImage']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      computedItems () {
        return this.items.map(this.mapItem)
      },
      parsedDirection () {
        return this.direction.split(' ')
      },
      profile () {
        return {
          avatar: true,
          title: this.$t('site_title'),
        }
      },
    },
    created () {
      this.VUE_APP_SITE_ID = this.$cookie.get('website_id')
    },
    mounted () {
      this.infoUser = this.$route.params.infoUser
      var idcommandeTOAPi
      if (typeof this.$route.params.infoUser !== 'undefined') {
        this.FromUser = true
      }
      if (typeof this.$route.params.infoCommande !== 'undefined') {
        this.infoCommande = this.$route.params.infoCommande
        this.$localStorage.set('idCommande', this.infoCommande.id_commande)
      }
      idcommandeTOAPi = this.$localStorage.get('idCommande')
      // } else {
      Vue.axios.get('https://api.espace-jantes.com/getOrder', {
        params: { id_commande: idcommandeTOAPi },
      })
        .then((response) => {
          var dateSplit = response.data[0].date_creation.split('T')
          var swicthDate = dateSplit[0].split('-')
          var heureCommande = dateSplit[1].substr(0, 5)
          response.data[0].heureCommande = heureCommande

          response.data[0].dateFormater = swicthDate[2] + '/' + swicthDate[1] + '/' + swicthDate[0]
          response.data[0].labelTypePaiemeent = this.GetTypePaiement(response.data[0].type_paiement)
          response.data[0].montant = (parseFloat(response.data[0].montant)).toFixed(2)
          response.data[0].adresse_facturation = JSON.parse(response.data[0].adresse_facturation)
          response.data[0].adresse_livraison = JSON.parse(response.data[0].adresse_livraison)
          response.data[0].valves = (response.data[0].hadValves === 1) ? 'oui' : 'non'
          if (response.data[0].status === 1) {
            this.transfertDataLayer = true
          } else {
            this.transfertDataLayer = false
          }
          this.infoCommande = response.data[0]
          this.infoCommande.vehicule = JSON.parse(response.data[0].voitureClient)
          this.InformationNumeroCommande = 'Information commande N° ' + response.data[0].numero
          this.userId = response.data[0].id_client
          this.infoCommande.numero_colis = (this.infoCommande.numero_colis === 'null') ? '' : this.infoCommande.numero_colis
          this.infoCommande.url_suivi_colis = (this.infoCommande.url_suivi_colis === 'null') ? '' : this.infoCommande.url_suivi_colis
          this.$localStorage.set('idUser', response.data[0].id_client)
          Vue.axios.get('https://api.espace-jantes.com/getOneUser', {
            params: { id_user: this.userId },
          })
            .then((response) => {
              response.data[0].statusLibelle = this.GetStatutCLientLibelle(response.data[0].status)
              this.infoClient = response.data[0]
            })
            .catch(error => console.log(error))
        })
        .catch(error => console.log(error))
      // }
      // Récupération detail de la commandes
      Vue.axios.get('https://api.espace-jantes.com/getDetailsOrder', {
        params: { id_commande: idcommandeTOAPi },
      })
        .then((response) => {
          response.data.forEach(element => {
            element.descriptif = JSON.parse(element.descriptif)
            if (element.reference !== 'EJ-accessoires') {
              if (element.descriptif.type === 2) {
                // si Pneu
                element.descriptif.largeur = element.descriptif.HBV + '/' + element.descriptif.width
                element.descriptif.offset = element.descriptif.indiceDeCharge
                element.descriptif.alesage = element.descriptif.indiceDeVitesse
                element.descriptif.entraxe = element.descriptif.specificite
                element.descriptif.couleur = element.descriptif.saison
                if (element.descriptif.couleur === 'ete') element.descriptif.couleur = 'été'
              }
              this.produitsTmp2.push(element)
              this.produitsTmp3.push(element)
            } else {
              this.produitsAccessoires.push(element)
            }
          })
          this.produitsTmp2.forEach((elementProduit, index) => {
            if (elementProduit.descriptif.type === 1) {
              this.produitsTmp3.forEach((elementProduit2, index2) => {
                if (elementProduit.id !== elementProduit2.id && JSON.stringify(elementProduit.descriptif) === JSON.stringify(elementProduit2.descriptif)) {
                  elementProduit2.quantite = 4
                  elementProduit2.prixFinalAffichage = elementProduit2.prix * 2
                  this.produits.push(elementProduit2)
                  this.produitsTmp2.splice(index, 1)
                  this.produitsTmp3.splice(index2, 1)
                } else if (elementProduit.id !== elementProduit2.id && elementProduit.descriptif.type === elementProduit2.descriptif.type) {
                  elementProduit2.prixFinalAffichage = elementProduit2.prix
                  this.produits.push(elementProduit2)
                }
              })
            }
            if (elementProduit.descriptif.type === 2) {
              this.produitsTmp3.forEach((elementProduit2, index2) => {
                if (elementProduit.id !== elementProduit2.id && (elementProduit.descriptif.width) === (elementProduit2.descriptif.width) && (elementProduit.descriptif.HBV) === (elementProduit2.descriptif.HBV)) {
                  elementProduit2.quantite = 4
                  elementProduit2.prixFinalAffichage = elementProduit2.prix * 2
                  this.produits.push(elementProduit2)
                  this.produitsTmp2.splice(index, 1)
                  this.produitsTmp3.splice(index2, 1)
                } else if (elementProduit.id !== elementProduit2.id && elementProduit.descriptif.type === elementProduit2.descriptif.type) {
                  elementProduit2.prixFinalAffichage = elementProduit2.prix
                  this.produits.push(elementProduit2)
                }
              })
            }
          })
        })
        .catch(error => console.log(error))
    },
    methods: {
      removeFromArray (arr) {
        var what; var a = arguments; var L = a.length; var ax
        while (L > 1 && arr.length) {
          what = a[--L]
          while ((ax = arr.indexOf(what)) !== -1) {
            arr.splice(ax, 1)
          }
        }
        return arr
      },
      GetStatutCLientLibelle: function (idStatut) {
        var statutLibelle = (idStatut === 3) ? 'Particulier' : 'Pro'
        return statutLibelle
      },
      GoDetailsUser: function (userID) {
        this.$router.push({ name: 'UsersDetails', params: { userID: userID, test: 'ok' } })
      },
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title),
        }
      },
      ModifierCommande: function (infoCommande) {
        if ((this.infoCommande.type_paiement === 4 || this.infoCommande.type_paiement === 5) && this.transfertDataLayer && this.infoCommande.statut === 2) {
          var montantDatalayer = (parseFloat(this.infoCommande.montant) + parseFloat(9.90)).toFixed(2)
          this.$gtm.push({
            event: 'transaction',
            ecommerce: {
              purchase: {
                actionField: {
                  id: this.infoCommande.numero, // Transaction ID. Required for purchases and refunds.
                  affiliation: 'Espace Jantes',
                  revenue: montantDatalayer, // Total transaction value (incl. tax and shipping)
                  tax: '0',
                  shipping: '0',
                },
                products: this.produits,
              },
            },
          })
        }
        Vue.axios.put('https://api.espace-jantes.com/updateOrders', {
          params: { infoCommande: infoCommande[0], infoProduits: this.produitsTmp },
        })
          .then((response) => {
            if (response.data.etat) {
              this.updateOk = true
              this.colorSnackbarSuccess = 'success'
            } else {
              this.KOUpdate = true
              this.colorSnackbarError = 'error'
            }
          })
          .catch(error => console.log(error))
      },
      GetTypePaiement: function (idTypePaiement) {
        var elementToReturn
        switch (idTypePaiement) {
          case 1:
            elementToReturn = 'Carte Bleu'
            break
          case 2:
            elementToReturn = 'Paypal'
            break
          case 3:
            elementToReturn = 'Cofidis 4x'
            break
          case 4:
            elementToReturn = 'Virement'
            break
          case 5:
            elementToReturn = 'Chèque'
            break
          default:
            elementToReturn = ''
        }
        return elementToReturn
      },
      // getDataUri: function (url) { return new Promise ( function (resolve, reject) {
      //     var image = new Image()
      //     image.setAttribute('crossOrigin', 'anonymous') // getting images from external domain

      //     image.onload = function () {
      //       var canvas = document.createElement('canvas')
      //       canvas.width = this.naturalWidth
      //       canvas.height = this.naturalHeight
      //       // next three lines for white background in case png has a transparent background
      //       var ctx = canvas.getContext('2d')
      //       ctx.fillStyle = '#fff' /// set white fill style
      //       ctx.fillRect(0, 0, canvas.width, canvas.height)
      //       canvas.getContext('2d').drawImage(this, 0, 0)
      //       resolve(canvas.toDataURL('image/jpeg'))
      //     }

      //     image.src = url
      //   })
      // },
      ExporterCommande: function () {
        const doc = new JSPDF()
        var img = new Image()
        console.log('infoCommande', this.infoCommande)
        console.log('infoClient', this.infoClient)
        if (this.VUE_APP_SITE_ID === '1') {
          img.src = this.imgFactureEJ
          doc.addImage(img, 'JPEG', 0, 0, 100, 40)
        } else if (this.VUE_APP_SITE_ID === '2') {
          img.src = this.imgFactureDJ
          doc.addImage(img, 'JPEG', 5, 5, 100, 28)
        }

        var texteInformationEJ = 'Pour éviter tout litige de transport, il est impératif de vérifier le bon état des produits ainsi que leurs contenus en présence du chauffeur.'
        texteInformationEJ += ' Toute réclamation devra être notifiée sur le bordereau de transport.Le colis devra être refusé et remis au transporteur le jour de la livraison.'
        texteInformationEJ += ' Aucun retour ne sera accepté sans vérification du colis lors de la réception et au-dela d\'un délai de 24H.'
        texteInformationEJ += ' La législation des transports prévoit que toute marchandise voyage aux risques et périls du destinataire.'
        texteInformationEJ += ' (Article 105 et 106 du code du commerce). '
        doc.setFontSize(20)
        // doc.addImage(logo, 'JPEG', 15, 40, 180, 160)
        doc.text('Facture', 10, 44, null, null)
        doc.setFontSize(12)
        var addMarge = 0
        if (this.infoCommande.adresse_facturation.societe !== '' && this.infoCommande.adresse_facturation.societe !== null && this.infoCommande.adresse_facturation.societe !== undefined) {
          doc.text(this.infoCommande.adresse_facturation.societe, 10, 55, null, null)
          addMarge = 6
        }
        doc.text(this.infoCommande.adresse_facturation.nom + ' ' + this.infoCommande.adresse_facturation.prenom, 10, (55 + addMarge), null, null)
        doc.text(this.infoCommande.adresse_facturation.adresse, 10, (61 + addMarge), null, null)
        if (this.infoCommande.adresse_facturation.complement_adresse !== '' && this.infoCommande.adresse_facturation.complement_adresse !== null && this.infoCommande.adresse_facturation.complement_adresse !== undefined) {
          doc.text(this.infoCommande.adresse_facturation.complement_adresse, 10, (67 + addMarge), null, null)
        }
        doc.text(this.infoCommande.adresse_facturation.code_postal + ' ' + this.infoCommande.adresse_facturation.ville, 10, (72 + addMarge), null, null)

        if (this.VUE_APP_SITE_ID === '1') {
          doc.text('Espace - Jantes SARL', 110, 17, null, null)
          doc.text('23 Rue Benjamin Franklin,', 110, 23, null, null)
          doc.text('84120 Pertuis', 110, 29, null, null)
          doc.setFontSize(9)
          doc.text('Siret: 53043737500039', 110, 35, null, null)
          doc.text('RCS: Avignon', 110, 39, null, null)
          doc.text('APE : 4532Z', 110, 43, null, null)
          doc.text('N° TVA intracommunautaire: FR 74530437375', 110, 47, null, null)
        } else if (this.VUE_APP_SITE_ID === '2') {
          doc.text('Distri - Jantes SAS', 110, 17, null, null)
          doc.text('1175 Route d\'Avignon,', 110, 23, null, null)
          doc.text('13090 Aix-en-Provence', 110, 29, null, null)
          doc.setFontSize(9)
          doc.text('Siret: 87814711500017', 110, 35, null, null)
          doc.text('RCS: Aix en provence', 110, 39, null, null)
          doc.text('APE : 4791B', 110, 43, null, null)
          doc.text('N° TVA intracommunautaire: FR 24878147115', 110, 47, null, null)
        }

        doc.setFontSize(12)
        doc.text('N° de la facture:', 110, 55, null, null)
        doc.setFontSize(10)
        doc.text(this.infoCommande.numero, 145, 55, null, null)
        doc.setFontSize(12)
        doc.text('Date de la facture:', 110, 61, null, null)
        doc.setFontSize(10)
        doc.text(this.infoCommande.dateFormater, 150, 61, null, null)
        doc.setFontSize(12)
        doc.text('Date de commande:', 110, 67, null, null)
        doc.setFontSize(10)
        doc.text(this.infoCommande.dateFormater, 150, 67, null, null)
        doc.setFontSize(12)
        doc.text('Méthode de paiement:', 110, 73, null, null)
        doc.setFontSize(10)
        doc.text(this.infoCommande.labelTypePaiemeent, 155, 73, null, null)
        doc.setFontSize(11)
        doc.setLineWidth(6.5)
        doc.line(10, 118, 200, 118)
        doc.setTextColor(150)
        doc.text('Produits', 11, 120, null, null)
        doc.text('Prix HT', 130, 120, null, null)
        doc.text('TVA 20%', 150, 120, null, null)
        doc.text('Prix TTC', 180, 120, null, null)
        doc.setTextColor(0)
        doc.setFontSize(12)
        var hauteurInitial = 130
        var sousTotal = 0
        var tvaTotal = 0
        this.produits.forEach(produit => {
          var textProduit
          var textDescriptionProduit
          if ((produit.descriptif.type === 1)) {
            textProduit = produit.marque + ' ' + produit.modele + ' ' + produit.descriptif.couleur + ' Qté: ' + produit.quantite
            textDescriptionProduit = produit.descriptif.largeur + 'x' + produit.descriptif.diametre + ' ' + produit.descriptif.entraxe.toString() + ' ET' + produit.descriptif.offset + ' ' + produit.descriptif.alesage
          } else {
            textProduit = produit.marque + ' ' + produit.modele + ' ' + ' Qté: ' + produit.quantite
            textDescriptionProduit = produit.descriptif.HBV + '/' + produit.descriptif.width + 'R' + produit.descriptif.diametre + ' '
            if (produit.descriptif.indiceDeCharge !== undefined && produit.descriptif.indiceDeVitesse !== undefined) {
              textDescriptionProduit += produit.descriptif.indiceDeCharge + ' ' + produit.descriptif.indiceDeVitesse
              if (produit.descriptif.specificite) { textDescriptionProduit += ' (' + produit.descriptif.specificite + ')' }
            }
          }
          // var textProduit = produit.marque + ' ' + produit.modele + ' ' + (produit.descriptif.type === 1) ? produit.descriptif.couleur : '' + ' Qté: ' + produit.quantite
          doc.text(textProduit, 11, hauteurInitial, null, null)
          doc.text(textDescriptionProduit, 11, hauteurInitial + 7, null, null)
          if (produit.quantite === 4 && produit.prixFinalAffichage !== undefined) {
            produit.prixFinalAffichage = produit.prix * 2
          } else if (produit.prixFinalAffichage === 'undefined' || produit.prixFinalAffichage === undefined) {
            produit.prixFinalAffichage = produit.prix * 4
          }
          doc.text((parseFloat(produit.prixFinalAffichage) / parseFloat(1.20)).toFixed(2) + '€', 130, hauteurInitial, null, null)
          var tva = (produit.prixFinalAffichage - (parseFloat(produit.prixFinalAffichage) / parseFloat(1.20)))
          doc.text(tva.toFixed(2) + '€', 150, hauteurInitial, null, null)
          doc.text((produit.prixFinalAffichage).toFixed(2) + '€', 180, hauteurInitial, null, null)
          doc.setLineWidth(0.1)
          doc.line(10, hauteurInitial + 9, 200, hauteurInitial + 9)
          hauteurInitial += 18
          sousTotal = parseFloat(sousTotal) + parseFloat(produit.prixFinalAffichage)
          tvaTotal = parseFloat(tvaTotal) + parseFloat(tva)
        })
        this.produitsAccessoires.forEach(produit => {
          var textProduit = produit.marque + ' ' + produit.modele + ' Qté: ' + produit.quantite
          doc.text(textProduit, 11, hauteurInitial, null, null)
          doc.text((parseFloat(produit.prix) / parseFloat(1.20)).toFixed(2) + '€', 130, hauteurInitial, null, null)
          var tva = (parseFloat(produit.prix) - ((parseFloat(produit.prix) / parseFloat(1.20)).toFixed(2)))
          doc.text(tva.toFixed(2) + '€', 150, hauteurInitial, null, null)
          doc.text((produit.prix).toFixed(2) + '€', 180, hauteurInitial, null, null)
          doc.setLineWidth(0.1)
          doc.line(10, hauteurInitial + 3, 200, hauteurInitial + 3)
          hauteurInitial += 9
          sousTotal = parseFloat(sousTotal) + parseFloat(produit.prix)
          tvaTotal = parseFloat(tvaTotal) + parseFloat(tva)
        })
        doc.setFontSize(8)
        if (this.infoClient.status === 2) {
          doc.text('Vous bénéficiez d\'une remise de ' + this.tauxRemise + '% en tant que revendeur (la remise a été appliquée au préalable de cette facture)', 11, 240)
        }
        var splitTitle = doc.splitTextToSize(texteInformationEJ, 190)
        doc.text(splitTitle, 10, 248, null, null)
        doc.setFontSize(12)
        doc.line(100, 260, 200, 260)
        doc.setTextColor(0)
        doc.setFontSize(10)
        doc.text('Sous-total :', 100, 265, null, null)
        doc.text(tvaTotal.toFixed(2).toString() + '€', 150, 265, null, null)
        doc.text(sousTotal.toFixed(2).toString() + '€', 180, 265, null, null)
        doc.text('Expédition :', 100, 270, null, null)
        doc.text('Livraison gratuite', 180, 270, null, null)
        doc.text('Frais d\'emballage :', 100, 275, null, null)
        doc.text('9.90 €', 180, 275, null, null)
        var total = parseFloat(sousTotal) + parseFloat(9.90)
        // if (this.infoCommande.type_paiement === 3) {
        //   total = parseFloat(this.infoCommande.montant)
        // }
        doc.text('Total :', 100, 280, null, null)
        doc.text(total.toFixed(2).toString() + '€', 180, 280, null, null)
        doc.setFontSize(8)
        var texteInformationEJBottom = "Pas d'escompte pour paiement anticipé, passée la date d'échéance, tout paiement différé entraine l'application d'une pénalité de 3 fois le taux d'intérêt légal.(loi N°2008-776 du 4/8/2008) ainsi qu'une indemnité forfaitaire pour frais de recouvrement de 40€uros-décret 2012-1115 du 2/10/2012."
        var splitTitleBottom = doc.splitTextToSize(texteInformationEJBottom, 190)
        doc.text(splitTitleBottom, 10, 287, null, null)
        doc.setFontSize(10)
        doc.save('Facture-' + this.infoCommande.numero + '.pdf')
      },
      MailLivraison: function (infoCommande) {
        let websiteid
        if (infoCommande.numero.substr(0, 2) === 'EJ') {
          websiteid = '1'
        } else if (infoCommande.numero.substr(0, 2) === 'DJ') {
          websiteid = '2'
        }

        if (infoCommande.numero_colis === '' || infoCommande.statut !== 4) {
          return false
        }
        Vue.axios.put('https://api.espace-jantes.com/updateOrders', {
          params: { infoCommande: infoCommande, infoProduits: this.produitsTmp },
        })
          .then((response) => {
            if (response.data.etat) {
              this.envoiMailEnvoiCommandeOK = true
              this.colorSnackbarSuccess = 'success'
              Vue.axios.get('https://api.espace-jantes.com/send-mail-envoi-en-cours', {
                params: { numeroCommande: infoCommande.numero, websiteid: websiteid },
              })
                .then((response) => {
                  // console.log(response)
                })
                .catch(error => console.log(error))
            } else {
              this.envoiMailEnvoiCommandeKO = true
              this.colorSnackbarError = 'error'
            }
          })
          .catch(error => console.log(error))
      },
      MailPreparation: function (infoCommande) {
        console.log('infoCommande', infoCommande)

        let websiteid
        if (infoCommande.numero.substr(0, 2) === 'EJ') {
          websiteid = '1'
        } else if (infoCommande.numero.substr(0, 2) === 'DJ') {
          websiteid = '2'
        }

        Vue.axios.get('https://api.espace-jantes.com/send-mail-commande-en-cours-de-preparation', {
          params: { numeroCommande: infoCommande.numero, websiteid: websiteid },
        })
          .then((response) => {
            this.envoiMailPreparationOK = true
            this.colorSnackbarSuccess = 'success'
          })
          .catch(error => {
            console.log(error)
            this.envoiMailPreparationKO = true
            this.colorSnackbarError = 'error'
          })
      },
      modifierNote: function (note) {
        // console.log(note)
      },
    },
  }
</script>
<style scoped>
  input:focus {
    border: 2px solid black !important;
    border-radius : 4px;
  }
  th{
    font-size: 14px !important;
  }
</style>
